<template>
  <div>
    <b-tabs
      content-class="mt-0"
      ref="tabs"
      class="report-tabs"
      v-model="tabIndex"
      align="center"
    >
      <b-tab title="ORDERS" active id="orders">
        <Order v-if="tabIndex == 0" :channelList="channelList" />
      </b-tab>
      <b-tab title="RETURNS" v-if="isPermission('allow_view_return_orders')">
        <Returns v-if="tabIndex == 1" :channelList="channelList" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Order from "../components/Orders/Order.vue";
import Returns from "../components/Orders/Returns.vue";
import { getChannelList, isAuthorized } from "../Service/CommonService";

export default {
  components: {
    Order,
    Returns,
  },
  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      tabIndex: 0,
      channelList: [],
    };
  },
  created() {
    this.getChannelList();
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },


    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelList.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px){
  ::v-deep .report-tabs .nav{
    border-bottom: 1px solid #dee2e6 !important;
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 480px){
  .report-tabs ::v-deep .nav-tabs{
    justify-content: center !important;
  }
}
</style>
