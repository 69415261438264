<template>
  <div class="upload-shipment">

    <div class="row mx-2 mb-3 px-2 d-flex justify-content-between align-items-center">
      <div class="upload-heading">Select Warehouse</div>
      <div
        role='button'
        @click="goBackToPreviousPage"
      >
        <img src="../../assets/CloseModel.svg" width="20px" height="20px"> 
      </div>
    </div>

    <div class="m-2">
      <div class="row px-2">
        <div class="col-lg-4 col-md-4 col-6" v-for="(data, i) in warehoueDetails" :key="i">
          <div class="card-body" :class="{active : isWarehouseSelected == i}" @click="selectWarehouse(data, i)">
            <div class="pb-2 card-heading">
              <p class="mb-0 warehouse-title">{{data.warehouseName}}</p>
              <p class="mb-0 warehouse-subTitle">{{data.warehouseType}}</p>
            </div>
            <div class="py-2 d-flex align-items-baseline card-detail">
              <div><img src="../../assets/SVG/placeholder.svg" width="15px" /></div>
              <span>
                {{data.addressLine1}}{{data.addressLine2}}{{data.city}}
                {{data.state}}{{data.country}}{{data.pinCode}}
              </span>
            </div>
            <div class="py-2 card-detail">
              <img src="../../assets/SVG/user.svg" width="15px" />
              <span>{{data.managerName}}</span>
            </div>
            <div class="pt-2">
              <span class="card-status" style="color:#363535">Status</span> 
              <span class="px-3">:</span>
              <span class="card-status" style="color:#767676">{{data.warehouseStatus}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end mb-4 mx-3">
          <w-button-input 
            :isDisabled="isSubmitBtnDisabled"
            :buttonClass="'auth-button'"
            :buttonStyle="'auth-button-style'"
            :label="'Select Warehouse'"
            @buttonClicked="createShipmentWithWarehouse(selectWarehouseID)"
          />
          <w-button-input 
            class="ml-3"
            :buttonClass="'request-demo'"
            :buttonStyle="'auth-button-style'"
            :label="'Cancel'"
            @buttonClicked="goBackToPreviousPage"
          />
      </div>
    </div>

   

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import ButtonInput from "../../widgets/InputButton.vue";
import Modal from "../../widgets/ModalWidget";
import {getWarehouse} from "../../Service/WarehouseServiceApi";

export default {
  components: {
    "w-button-input": ButtonInput,
    Modal,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      warehoueDetails:"",
      isWarehouseSelected:null,
      selectWarehouseID:"",

    };
  },

  created() {
    this.getWarehouse();
  },

  computed: {
    isSubmitBtnDisabled() {
      if (!this.selectWarehouseID == "") {
        return false;
      }
      return true;
    },
  },

  methods: {

    // get all warehoue list
    getWarehouse(){
      getWarehouse()
      .then((res) => {
        if(res){
          this.warehoueDetails= res.content;
        }
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },

    // select warehoue
    selectWarehouse(data, i){
      this.isWarehouseSelected = i;
      this.selectWarehouseID = data.warehouseId;
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for navigation
    goBackToPreviousPage() {
      this.$emit("goBackToPreviousPage")
    },
    // create shipment with warehouse id
    createShipmentWithWarehouse(warehoueID){
      this.$emit("createShipmentWithWarehouse", warehoueID)
    },
    
  },
};
</script>

<style scoped>
.upload-shipment{
  background: #fff;
  /* box-shadow: 0px 1px 3px #00000029 */
}
.upload-heading{
  color: #1B1C1D;
  font-size: 18px;
  font-weight: 600;
}
.upload-shipment ::v-deep .request-demo{
  background: #fff;
  border: 1px solid #005D84;
  padding: 0.5rem !important;
}
.active{
  border: 1px solid #023449;
}
.card-body{
  padding:15px 10px 15px 20px; 
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  cursor: pointer;
}
.warehouse-title{
  font-size: 17px;
  font-weight: 600;
  color: #090909;
}
.warehouse-subTitle{
  font-size: 12px;
  font-weight: normal;
  color: #363535;
  opacity: 0.5;
}
.card-heading{
  border-bottom: 1px solid #00000029;
}
.card-detail{
  font-size:13px; 
  color:#363535; 
  border-bottom: 1px solid #00000029;
}
.card-detail span{
  color: #363535;
  font-size: 13px;
  font-weight: normal;
  padding-left: 15px;
  opacity: 0.85;
}
.card-status{
  font-size:13px;
}

</style>
