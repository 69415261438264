<template>
  <div>
    <!-- filter for mobile -->
    <!-- <div class="open-mobile-filter" v-if="isMobile_filter_open">
      <div class="row mx-2">
        <div class="col-2 pt-4">
          <span @click="closeApplyFilter">
            <img src="../../assets/SVG/arrow-back.svg" />
          </span>
        </div>
        <div
          class="col-8 pt-0 d-flex justify-content-center"
          style="border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
        >
          <span
            class="text-white bg-dark py-3 px-4"
            style="font-size:20px; border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
            >LOGO</span
          >
        </div>
        <div class="col-2"></div>
      </div>

      <b-card class="m-2 ml-2 mr-2 order-filter" :body-class="'card-padding'">
        <div class="row">
          <div class="col-md-3">
            <label class="tree-label">OrderID/Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="orderId"
              :labelStyle="'register-label'"
              :fieldName="'OrderID/Channel Order ID'"
              @textInputChange="getOrderId($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="tree-label">Sort By</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedSortBy"
              :selectOption="sortByOptions"
              @selectedOption="getSortBy($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Channels'"
              :rootOptions="channelList"
              :selectedValue="selectedChannelList"
              @selectedField="getSelectedChannel($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Fulfilment Type'"
              :rootOptions="fulfillmentTypes"
              :selectedValue="selectedFullfillmentList"
              @selectedField="getSelectedTypes($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Shipment Type'"
              :rootOptions="shipmentTypes"
              :selectedValue="selectedShipmentList"
              @selectedField="getSelectedShipmentTypes($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Shipment Status'"
              :rootOptions="shipmentStatus"
              :selectedValue="selectedShipmentStatusList"
              @selectedField="getSelectedShipmentStatus($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="ml-3 tree-label">Purchased Date</label>
            <date-picker
              v-model="selectedPurchaseDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearPurchaseDate($event)"
              @confirm="getPurchaseDate($event)"
            ></date-picker>
          </div>
        </div>
      </b-card>
    </div> -->
    <!-- mobile filter end -->

    <div v-if="!isOrderDetailsTab" class="mt-2 mobile-top">
      <!-- filter button for mobile -->
      <div class="d-flex justify-content-between mx-2">
        <div>
          <span class="ml-2 summary_refresh_text"
            >Last updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
          >&nbsp;&nbsp;<span class="summary_refresh">
            <img
              style="cursor:pointer"
              @click="loadData()"
              src="../../assets/refresh.png"
              width="15px"
            />
          </span>
        </div>
        <!-- <div class="text-right mobile-filter">
          <p class="mb-0 mx-2">
            <span @click="onApplyFilter">
              <img src="../../assets/SVG/filter-results-button.svg" />
            </span>
          </p>
        </div> -->
      </div>
      <!-- end -->

      <!-- <b-card class="m-2 ml-2 mr-2"> -->
      <div class="m-3 pb-2 order-count">
        <div
          class="m-0"
          :id="tab.text"
          v-for="tab in orderTabs"
          :key="tab"
          @click="getSelectedOrderTab(tab)"
        >
          <span :class="tab.active ? 'active-class' : 'inactive-class'" v-if="isPermission(tab.permission)">
            {{ tab.text }} ({{ tab.count }})
          </span>
        </div>
      </div>
      <!-- </b-card> -->
      <b-card class="order-filter desktop-order-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
        <div class="row">
          <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" @click="closeDesktopFilter">
          <div class="col-md-3 pb-0" id="orders-id">
            <label class="tree-label">OrderID/Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="orderId"
              :labelStyle="'register-label'"
              :fieldName="'OrderID/Channel Order ID'"
              @textInputChange="getOrderId($event)"
            />
          </div>
          <div class="col-md-3 pb-0" id="sort-by">
            <label class="tree-label">Sort By</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedSortBy"
              :selectOption="sortByOptions"
              @selectedOption="getSortBy($event)"
            />
          </div>
          <div class="col-md-3 pb-0" id="channel-id">
            <TreeDropdown
              :labelText="'Channels'"
              :rootOptions="channelList"
              :selectedValue="selectedChannelList"
              @selectedField="getSelectedChannel($event)"
            />
          </div>
          <div class="col-md-3 pb-0" id="fulfilment-id">
            <TreeDropdown
              :labelText="'Fulfilment Type'"
              :rootOptions="fulfillmentTypes"
              :selectedValue="selectedFullfillmentList"
              @selectedField="getSelectedTypes($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pt-0" id="ship-id">
            <TreeDropdown
              :labelText="'Shipment Type'"
              :rootOptions="shipmentTypes"
              :selectedValue="selectedShipmentList"
              @selectedField="getSelectedShipmentTypes($event)"
            />
          </div>
          <div class="col-md-3 pt-0" id="ship-status">
            <TreeDropdown
              :labelText="'Shipment Status'"
              :rootOptions="shipmentStatus"
              :selectedValue="selectedShipmentStatusList"
              @selectedField="getSelectedShipmentStatus($event)"
            />
          </div>
          <!-- <div class="col-md-3 pt-0">
            <TreeDropdown
              :labelText="'Categories'"
              class="mt-2"
              :rootOptions="channelList"
              :selectedValue="categories"
              @selectedField="getSelectedChannel($event)"
            />
          </div> -->
          <div class="col-md-3 pt-0" id="purchase-date">
            <label class="ml-3 tree-label">Purchased Date</label>
            <date-picker
              v-model="selectedPurchaseDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearPurchaseDate($event)"
              @confirm="getPurchaseDate($event)"
            ></date-picker>
          </div>
        </div>
      </b-card>
      <div class="order-table">
        <w-orders-table
          :isFilterButton="true"
          :tableLoading="tableLoading"
          :tableProperties="tableProperties"
          @showDeleteOption="showDeleteOption"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          @getSelectedPageEvent="getSelectedPage($event)"
          @goToOrderDetailsPageEvent="goToOrderDetailsPage($event)"
          @downloadOrderEvent="downloadOrder"
          @createShipmentEvent="createShipmentEvent()"
          @openDesktop_filterEvent="openDesktop_filter"
          :permissionShipment="'allow_create_shipment'"
        />
      </div>
    </div>
    <div class="order-detail-page" v-else>
      <w-Order-Details-Table
        :orderIdParams="orderDetialsParams.orderId"
        :channelIdParams="orderDetialsParams.channelId"
        :companyIdParams="orderDetialsParams.companyId"
        @gotoBackPage="goBackToOrder()"
      />
    </div>

    <!-- select warehouse easyship template -->
    <b-modal id="warehouse-modal" size="xl" hide-footer="true" hide-header-close centered>
      <w-warehouseTemplate @goBackToPreviousPage="goBackToPreviousPage" @createShipmentWithWarehouse="createShipmentWithWarehouse($event)" />
    </b-modal>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import Modal from "../../widgets/ModalWidget";
import moment from "moment";
import moment_timezone from "moment-timezone";
import OrderDetailsTable from "../../widgets/OrderDetailsTables.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import WarehouseTemplate from "./WarehouseTemplate.vue";

import {
  getTypes,
  getShipmentTypes,
  getShipmentStatus,
  getOrderListWithFilter,
  downloadOrder,
  createShipment,
  getOrderCount,
} from "../../Service/OrderService";
import { isAuthorized } from "../../Service/CommonService";
export default {
  props: ["channelList"],
  components: {
    DatePicker,
    "w-text-input": TextInput,
    "w-orders-table": TableWithCheckbox,
    "w-dropdown": Dropdown,
    TreeDropdown,
    Modal,
    "w-Order-Details-Table": OrderDetailsTable,
    "w-loading-spinner": LoadingSpinner,
    "w-warehouseTemplate": WarehouseTemplate
  },
  data() {
    return {
      // isMobile_filter_open: false, // for mobile filter
      isDesktopFilterOpen:false, // for desktop filter
      companyID: sessionStorage.getItem("companyIds"),
      loadingWidget: false,
      tableLoading: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      orderDetialsParams: null,
      isOrderDetailsTab: false,
      isConfirmAlert: false,
      fulfillmentTypes: [],
      shipmentTypes: [],
      shipmentStatus: [],
      sortByOptions: [
        { value: "ASC", text: "Ascending" },
        { value: "DESC", text: "Descending" },
      ],
      orderTabs: [
        {
          text: "Unshipped",
          active: true,
          count: 0,
          permission: "allow_view_unshipped_orders",
          download: "allow_download_unshipped_orders"
        },
        {
          text: "Ready to Ship",
          active: false,
          count: 0,
          permission: "allow_view_ready_to_ship_orders",
          download: "allow_download_ready_to_ship_orders"
        },
        {
          text: "Shipped",
          active: false,
          count: 0,
          permission: "allow_view_shipped_orders",
          download: "allow_download_shipped_orders"
        },
        {
          text: "Canceled",
          active: false,
          count: 0,
          permission: "allow_view_cancelled_orders",
          download: "allow_download_cancelled_orders"
        },
        {
          text: "Pending",
          active: false,
          count: 0,
          permission: "allow_view_pending_orders",
          download: "allow_download_pending_orders"
        },
        {
          text: "All",
          active: false,
          count: 0,
          permission: "allow_view_all_orders",
          download: "allow_download_all_orders"
        },
      ],
      categories: [],
      selectedChannelList: [],
      selectedShipmentList: [],
      selectedShipmentStatusList: [],
      selectedFullfillmentList: [],
      selectedPurchaseDate: "",
      orderId: "",
      tableProperties: {
        fields: [
          {
            key: "checkbox",
            label: "",
            selected: false,
          },
          {
            key: "orderId",
            label: "Order ID/Channel Order ID",
            selected: false
          },
          // {
          //   key: "channelOrderId",
          //   label: "Channel Order ID",
          //   selected: false,
          //   thClass: "thClass",
          //   tdClass: "tdClass",
          // },
          {
            key: "channelName",
            label: "Channel Name",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "shipmentId",
            label: "Shipment ID",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderType",
            label: "Fulfilment Type",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderTotal",
            label: "Order Total",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "shipmentType",
            label: "Shipment Type",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "status",
            label: "Status",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "shippingStatus",
            label: "Shipment Status",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "shipBy",
            label: "Ship By",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "deliverBy",
            label: "Deliver By",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        allSelected: false,
        showButton: true,
        showCreateShipmentBtn: true,
        downloadBtn:false,
      },
      // companyId: 7,
      startDate: "",
      endDate: "",
      selectedSortBy: "DESC",
      orderDetails: [],
      buyerDetails: [],
      orderStatus: "Unshipped",
      orderItemsDetailsTableData: {
        items: [],
        fields: [
          {
            key: "slNo",
            label: "S.no",
          },
          {
            key: "sku",
            label: "SKU",
          },
          {
            key: "channelSku",
            label: "Channel SKU",
          },
          {
            key: "title",
            label: "Title",
            tdClass: "td-title-class",
          },
          {
            key: "quantity",
            label: "Quantity",
          },
        ],
        lastUpdated: "",
      },
      selectWarehouseID:"",
    };
  },
  created() {
    this.getTypes();
    this.getShipmentTypes();
    this.getShipmentStatus();
    this.loadData();

// permission based show download btn
    if(this.isPermission('allow_download_unshipped_orders')){
      this.tableProperties.downloadBtn = true;
    }else{
      this.tableProperties.downloadBtn = false;
    }
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    // open filter for mobile
    // onApplyFilter() {
    //   this.isMobile_filter_open = true;
    // },
    // closeApplyFilter() {
    //   this.isMobile_filter_open = false;
    // },

    loadData() {
      this.getLastUpdated();
      this.getOrderCount();
      this.getOrderList();
    },
    // To get  last updated time
    getLastUpdated() {
      this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    },
    getOrderCount() {
      getOrderCount()
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            this.getLastUpdated();
            this.orderTabs.forEach((tab) => {
              if (tab.text == "All") {
                tab.count = resp.all;
              }
              if (tab.text == "Canceled") {
                tab.count = resp.canceled;
              }
              if (tab.text == "Pending") {
                tab.count = resp.pending;
              }
              if (tab.text == "Shipped") {
                tab.count = resp.shipped;
              }
              if (tab.text == "Unshipped") {
                tab.count = resp.unshipped;
              }
              if (tab.text == "Ready to Ship") {
                tab.count = resp.readyToShip;
              }
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getStatusClass(status) {
      switch (status) {
        case "Shipped":
          return "shipped-status-class";
        case "Unshipped":
          return "unshipped-status-class";
        case "Canceled":
          return "canceled-status-class";
        case "Pending":
          return "pending-status-class";
        default:
          return "";
      }
    },
    getOrderList() {
      this.tableLoading = true;
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset)
      getOrderListWithFilter(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.selectedChannelList,
        this.selectedShipmentList,
        this.selectedShipmentStatusList,
        this.selectedFullfillmentList,
        this.orderId,
        this.selectedSortBy,
        this.orderStatus
      )
        .then((res) => {
          this.tableLoading = false;
          this.getLastUpdated();
          if (!res.errorCode) { 
            this.tableProperties.items = [];
            let resp = res.response;
            this.tableProperties.items = resp.content;
            this.tableProperties.items.forEach((item,index) => {
              item.selected = false;
              item.purchaseDate =  this.getPurchaseDateZoneWise(item.purchaseDate);
              let shipBy = item.shipBy != null ? item.shipBy.split(" to "): [];
              item.shipBy = shipBy.length > 0 ? `${this.getPurchaseDateZoneWise(shipBy[0])} to ${this.getPurchaseDateZoneWise(shipBy[1])}`:' - ';
              var deliverBy = item.deliverBy != null ? item.deliverBy.split(" to "):[]; 
              item.deliverBy =  deliverBy.length > 0 ? `${this.getPurchaseDateZoneWise(deliverBy[0])} to ${this.getPurchaseDateZoneWise(deliverBy[1])}`:'-';
            });
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getPurchaseDateZoneWise(dateAndTime){
      let localDateAndTime = moment.utc(dateAndTime,'DD-MM-YYYY hh:mm:ss').local().format('DD/MM/YYYY hh:mm A');
      return localDateAndTime;
    },
    getTypes() {
      getTypes()
        .then((res) => {
          if (!res.errorCode) {
            this.fulfillmentTypes = [];
            let resp = res.response;
            resp.forEach((element) => {
              this.fulfillmentTypes.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getShipmentTypes() {
      getShipmentTypes()
        .then((res) => {
          if (!res.errorCode) {
            this.shipmentTypes = [];
            let resp = res.response;
            resp.forEach((element) => {
              this.shipmentTypes.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for shipment status
    getShipmentStatus() {
      getShipmentStatus()
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.shipmentStatus.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getOrderList();
    },
    getPurchaseDate() {
      if (this.selectedPurchaseDate.length > 1) {
        this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
        this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
        this.getOrderList();
      }
    },
    getOrderId(event) {
      this.orderId = event;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    getSortBy(event) {
      this.selectedSortBy = event;
      this.getOrderList();
    },
    getSelectedChannel(event) {
      this.selectedChannelList = event;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    getSelectedTypes(event) {
      this.selectedFullfillmentList = event;
      this.getOrderList();
    },
    getSelectedShipmentTypes(event) {
      this.selectedShipmentList = event;
      this.getOrderList();
    },
    // for shipment status
    getSelectedShipmentStatus(event) {
      this.selectedShipmentStatusList = event;
      this.getOrderList();
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    downloadOrder() {
      this.loadingWidget = true;
      downloadOrder(
        this.orderStatus,
        this.orderId,
        this.selectedSortBy,
        this.selectedShipmentList,
        this.selectedShipmentStatusList,
        this.selectedFullfillmentList,
        this.selectedChannelList,
        this.startDate,
        this.endDate,
        this.companyID
      )
        .then((res) => {
          this.loadingWidget = false;
        })
        .catch((err) => {
          this.loadingWidget = false;
        });
    },

    createShipment() {
      let shipMentObj = {
        createdBy: 2,
        orderIds: [],
        warehouseId: this.selectWarehouseID,
        // warehouseId: "4797972d-c515-4687-b0e3-def8c0f360ee", // ( warehouse id is hardcoded now)
      };
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          shipMentObj.orderIds.push(item.orderId);
        }
      });

      if (shipMentObj.orderIds.length > 0) {
        createShipment(shipMentObj, this.companyID)
          .then((res) => {
            this.alert = true;
            this.alertMsg =
              res.status === "FAILURE" ? res.failureMessage : res.response;
            // "Your shipment ID is " + res.response.orderShipmentBatchId;
            this.alertType = "";
            this.isConfirmAlert = false;
            this.tableProperties.perPage = 50;
            this.tableProperties.currentPage = 1;
            this.tableProperties.allSelected = false;
            this.getOrderList();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.getOrderList();
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one order to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },
    confirmOk() {
      this.createShipment();
    },

    showConfirmModal() {
      this.alert = true;
      this.alertMsg = "Are you sure want to create shipment ?";
      this.alertType = "";
      this.isConfirmAlert = true;
    },

    createShipmentEvent(){
      let selectShipType =[];

      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          selectShipType.push(item.shipmentType);
        }
      });

      if(selectShipType.length > 0){
        if(selectShipType.includes("MARKETPLACE_LOGISTICS") && selectShipType.includes("SELFSHIP")){
          this.alert = true;
          this.alertMsg = "Please select only Selfship orders to proceed.";
          this.alertType = "";
          this.isConfirmAlert = false;
        }else if (!selectShipType.includes("MARKETPLACE_LOGISTICS")){
          this.showWarehouseTemplate();
        }else{
          this.selectWarehouseID=null;
          this.showConfirmModal();
        }
      }else{
        this.alert = true;
        this.alertMsg = "Please select atleast one order to proceed.";
        this.alertType = "";
        this.isConfirmAlert = false;
      }
      
    },

    createShipmentWithWarehouse(warehoueID){
      this.selectWarehouseID = warehoueID;
      this.showConfirmModal();
      this.goBackToPreviousPage();
    },
    
    getSelectedOrderTab(tab) {
      // if tab is "Unshipped then create shipment button will show otherwise hide"
      if (tab.text === "Unshipped") {
        this.tableProperties.showCreateShipmentBtn = true;
      } else {
        this.tableProperties.showCreateShipmentBtn = false;
      }

      if(this.isPermission(tab.download)){
        this.tableProperties.downloadBtn =true;
      }else{
        this.tableProperties.downloadBtn =false;
      }

      this.orderTabs.forEach((orderTab) => {
        if (orderTab.text === tab.text) {
          orderTab.active = true;
          this.orderStatus = tab.text;
        } else {
          orderTab.active = false;
          this.orderStatus = tab.text;
        }
        if (this.orderStatus == "Ready to Ship") {
          this.orderStatus = "ReadyToShip";
        }

      });
      this.getOrderList();
    },
    goBackToOrder() {
      this.isOrderDetailsTab = false;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    goToOrderDetailsPage(orderDetails) {
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },

    // open a desktop filer
    openDesktop_filter(){
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    },

    // open upload template modal
    showWarehouseTemplate(){
      this.$bvModal.show("warehouse-modal");
    },
    goBackToPreviousPage(){
      this.$bvModal.hide("warehouse-modal");
    }

  },
};
</script>

<style scoped>
.order-filter ::v-deep .custom-select,
.order-filter ::v-deep .form-control {
  height: 38px !important;
}
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.order-count {
  border-bottom: 2px solid #ccc;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 20px !important;
}
.order-count div{
  padding-right: 2rem;
}
.active-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #2e8cd3;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 3px solid #2e8cd3;
}
.inactive-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.striped-class {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.non-striped-class {
  background-color: #ffffff !important;
}
.back-button {
  width: 149px;
  height: 33px;
  border-radius: 2px;
  border: solid 1px #5fa6e7;
  background-color: #5fa6e7;
  line-height: normal;
}
.order-heading {
  font-size: 14px;
  font-weight: bold;
  color: #302e2e;
}
.order-table ::v-deep table thead tr th:first-child {
  z-index: 1;
}
.order-table ::v-deep table thead tr th {
  text-align: center !important;
}
.order-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.order-table ::v-deep table thead tr th:nth-child(2) {
  text-align: left !important;
}
.order-table ::v-deep table tbody tr td:nth-child(2) {
  text-align: left !important;
}
.order-table ::v-deep .desktop-download-ship-icons img:nth-child(2){
  margin-left: 20px !important;
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
.order-detail-page ::v-deep .whithout-tab-content{
  margin: 0;
}
.order-table ::v-deep .table-pagination{
  align-items: center !important;
}


/* .mobile-filter {
  display: none;
} */
/* .open-mobile-filter {
  display: none;
} */

/* for responsive */
@media only screen and (max-width: 340px){
  .order-table ::v-deep .table-pagination .page-link{
    min-width: 10px !important;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-top {
    /* margin-top: 55px !important; */
    margin-top: 15px !important;
  }
  .order-count {
    width: 94%;
    margin-bottom: 0 !important;
    border: none !important;
    overflow-x: auto;
    white-space: nowrap;
  }
  .order-count div {
    margin-right: 15px !important;
  }
  .active-class {
    padding: 6px 2px;
    font-size: 14px;
    border-bottom: 3px solid #2185D0;
  }
  /* .mobile-filter {
    display: block;
  } */
  /* .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    z-index: 222222;
  } */
  .desktop-order-filter {
    /* display: none; */
    /* height: 100vh;
    position: absolute;
    top: 0; */
    z-index: 2;
    margin-top: 2rem;
  }
  .card-padding{
    margin-top: 10px;
  }
  .close-filter-icon{
    top: 0;
  }
  /* ::v-deep .table-pagination{
    justify-content: end !important;
  } */
}
@media only screen and (max-width: 380px){
  .summary_refresh_text {
    font-size: 12px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px){
  .order-count div{
      padding-right: 15px;
  }
}

</style>
