<template>
  <div>
    <!-- filter for mobile -->
    <!-- <div class="open-mobile-filter" v-if="isMobile_filter_open">
      <div class="row mx-2">
        <div class="col-2 pt-4">
          <span @click="closeApplyFilter">
            <img src="../../assets/SVG/arrow-back.svg" />
          </span>
        </div>
        <div
          class="col-8 pt-0 d-flex justify-content-center"
          style="border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
        >
          <span
            class="text-white bg-dark py-3 px-4"
            style="font-size:20px; border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
            >LOGO</span
          >
        </div>
        <div class="col-2"></div>
      </div>

      <b-card class="mx-2 mt-5" :body-class="'card-padding'">
        <div class="row">
          <div class="col-md-3 pt-0">
            <label class="tree-label">OrderID/Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="orderId"
              :labelStyle="'register-label'"
              :fieldName="'OrderID/Channel Order ID'"
              @textInputChange="getOrderId($event)"
            />
          </div>
          <div class="col-md-3 pt-0">
            <TreeDropdown
              :labelText="'Status'"
              :rootOptions="statusList"
              :selectedValue="selectedStatusList"
              @selectedField="getSelectedStatus($event)"
            />
          </div>
          <div class="col-md-3 pt-0">
            <TreeDropdown
              :labelText="'Channels'"
              :rootOptions="channelList"
              :selectedValue="selectedChannelList"
              @selectedField="getSelectedChannel($event)"
            />
          </div>
          <div class="col-md-3 pt-0">
            <TreeDropdown
              :labelText="'Shipment Type'"
              :rootOptions="shipmentTypesList"
              :selectedValue="selectedShipmentList"
              @selectedField="getSelectedShipmentTypes($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Payment Type'"
              :rootOptions="paymentTypes"
              :selectedValue="selectedPaymentList"
              @selectedField="getSelectedPaymentTypes($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="ml-3 tree-label">Purchased Date</label>
            <date-picker
              v-model="selectedPurchaseDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearPurchaseDate($event)"
              @confirm="getPurchaseDate($event)"
            ></date-picker>
          </div>
        </div>
      </b-card>
    </div> -->
    <!-- mobile filter end -->

      <div v-if="!isOrderDetailsTab" class="mt-2 mobile-top-space">
        <!-- filter button for mobile -->
        <div class="d-flex justify-content-between mx-2">
          <div>
            <span class="ml-2 summary_refresh_text"
              >Last updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
            >&nbsp;&nbsp;<span class="summary_refresh">
              <img
                style="cursor:pointer"
                @click="loadData()"
                src="../../assets/refresh.png"
                width="15px"
              />
            </span>
          </div>
          <!-- <div class="text-right mobile-filter">
            <p class="mb-0 mx-2">
              <span @click="onApplyFilter">
                <img src="../../assets/SVG/filter-results-button.svg" />
              </span>
            </p>
          </div> -->
        </div>
        <!-- end -->

        <b-card class="m-2 desktop-return-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
          <div class="row">
            <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" @click="closeDesktopFilter">
            <div class="col-md-3 pt-0" id="order-ID">
              <label class="tree-label">OrderID/Channel Order ID</label>
              <w-text-input
                :fieldType="'text'"
                :disabled="false"
                :defaultValue="orderId"
                :labelStyle="'register-label'"
                :fieldName="'OrderID/Channel Order ID'"
                @textInputChange="getOrderId($event)"
              />
            </div>
            <div class="col-md-3 pt-0" id="statuss">
              <TreeDropdown
                :labelText="'Status'"
                :rootOptions="statusList"
                :selectedValue="selectedStatusList"
                @selectedField="getSelectedStatus($event)"
              />
            </div>
            <div class="col-md-3 pt-0" id="channnel">
              <TreeDropdown
                :labelText="'Channels'"
                :rootOptions="channelList"
                :selectedValue="selectedChannelList"
                @selectedField="getSelectedChannel($event)"
              />
            </div>
            <div class="col-md-3 pt-0" id="shipment-type">
              <TreeDropdown
                :labelText="'Shipment Type'"
                :rootOptions="shipmentTypesList"
                :selectedValue="selectedShipmentList"
                @selectedField="getSelectedShipmentTypes($event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3" id="pay-type">
              <TreeDropdown
                :labelText="'Payment Type'"
                :rootOptions="paymentTypes"
                :selectedValue="selectedPaymentList"
                @selectedField="getSelectedPaymentTypes($event)"
              />
            </div>
            <div class="col-md-3" id="purchased">
              <label class="ml-3 tree-label">Purchased Date</label>
              <date-picker
                v-model="selectedPurchaseDate"
                :lang="'en'"
                range
                confirm
                :shortcuts="[]"
                @clear="clearPurchaseDate($event)"
                @confirm="getPurchaseDate($event)"
              ></date-picker>
            </div>
          </div>
        </b-card>

        <div class="return-table">
          <w-orders-table
            :isFilterButton="true"
            :tableLoading="tableLoading"
            :tableProperties="tableProperties"
            @getSelectedPerPageEvent="getSelectedPerPage($event)"
            @getSelectedPageEvent="getSelectedPage($event)"
            @goToOrderDetailsPageEvent="goToOrderDetailsPage($event)"
            @downloadOrderEvent="downloadOrderReturns"
            @openDesktop_filterEvent="openDesktop_filter"
          />
        </div>
      </div>

      <!-- order detail -->
      <div class="order-detail-page" v-else>
        <w-Order-Details-Table
          :orderIdParams="orderDetialsParams.masterOrderId"
          :channelIdParams="orderDetialsParams.channelId"
          :companyIdParams="orderDetialsParams.companyId"
          @gotoBackPage="goBackToOrder()"
        />
      </div>

    <!-- modal -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import Modal from "../../widgets/ModalWidget";
import OrderDetailsTable from "../../widgets/OrderDetailsTables.vue";
import moment from "moment";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import {
  getReturnStatus,
  getShipmentTypes,
  getPaymentTypes,
  getReturnListWithFilter,
  downloadReturns,
} from "../../Service/OrderService";
import { isAuthorized } from "../../Service/CommonService";
import moment_timezone from "moment-timezone";

export default {
  props: ["channelList"],
  components: {
    DatePicker,
    "w-text-input": TextInput,
    "w-orders-table": TableWithCheckbox,
    "w-dropdown": Dropdown,
    TreeDropdown,
    Modal,
    "w-Order-Details-Table": OrderDetailsTable,
    "w-loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      // isMobile_filter_open: false, // for mobile filter
      isDesktopFilterOpen:false, // for desktop filter
      companyID: sessionStorage.getItem("companyID"), // company id coming from the login token
      // for error handling modal
      loadingWidget: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,
      isOrderDetailsTab: false,
      orderDetialsParams: null,

      orderId: "",
      statusList: [],
      selectedStatusList: [],
      selectedChannelList: [],
      shipmentTypesList: [],
      selectedShipmentList: [],
      paymentTypes: [],
      selectedPaymentList: [],
      startDate: "",
      endDate: "",
      selectedPurchaseDate: "",
      tableProperties: {
        fields: [
          {
            key: "checkbox",
            label: "",
            selected: false,
          },

          {
            key: "masterOrderId",
            label: "Order ID/Channel Order ID",
            selected: false,
          },

          {
            key: "channelName",
            label: "Channel Name",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          // {
          //   key: "shipmentType",
          //   label: "Fulfilment Type",
          //   selected: false,
          //   thClass: "thClass",
          //   tdClass: "tdClass",
          // },
          {
            key: "orderTotal",
            label: "Order Total",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "paymentType",
            label: "Payment Type",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "shipmentStatus",
            label: "Status",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          // {
          //   key: "action_field",
          //   label: "Action",
          //   selected: false,
          //   thClass: "thClass",
          //   tdClass: "tdClass",
          // },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        allSelected: false,
        // showReturnDownloadBtn: true,
        downloadBtn:true,
        showButton:true,
      },
      lastUpdated: "",
    };
  },
  created() {
    this.getReturnStatusList();
    this.getShipmentTypesList();
    this.getPaymentTypes();
    this.loadData();

  // permission based show download btn
    if(this.isPermission('allow_downlad_return_orders')){
      this.tableProperties.downloadBtn = true;
    }else{
      this.tableProperties.downloadBtn = false;
    }
  },

  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },

    // open filter for mobile
    // onApplyFilter() {
    //   this.isMobile_filter_open = true;
    // },
    // closeApplyFilter() {
    //   this.isMobile_filter_open = false;
    // },

    loadData() {
      this.getLastUpdated();
      this.getReturnOrdersList();
      this.tableProperties.totalRows = this.tableProperties.items.length;
    },
    // To get  last updated time
    getLastUpdated() {
      this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    },
    //  for status dropdown list
    getReturnStatusList() {
      getReturnStatus()
        .then((res) => {
          let resp = res.response;
          resp.forEach((element) => {
            this.statusList.push({
              id: element,
              label: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    //  for types dropdown list
    getShipmentTypesList() {
      getShipmentTypes()
        .then((res) => {
          let resp = res.response;
          resp.forEach((element) => {
            this.shipmentTypesList.push({
              id: element,
              label: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for payment type
    getPaymentTypes() {
      getPaymentTypes()
        .then((res) => {
          let resp = res.response;
          resp.forEach((element) => {
            this.paymentTypes.push({
              id: element,
              label: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for get return table list
    getReturnOrdersList() {
      this.tableLoading = true;
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset)
      getReturnListWithFilter(
        this.orderId,
        this.selectedStatusList,
        this.selectedChannelList,
        this.selectedShipmentList,
        this.selectedPaymentList,
        this.startDate,
        this.endDate,
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.companyID
      )
        .then((res) => {
          this.tableLoading = false;
          this.tableProperties.items = [];

          let resp = res.response;

          this.tableProperties.items = resp.content
          this.tableProperties.items.forEach((item,index) => {
            item.selected = false;
            item.purchaseDate = item.purchaseDate ? `${this.getPurchaseDateZoneWise(item.purchaseDate)} (${timeZone})` : '-';
          });
          // .map((el) => {
          //   return {
          //     ...el,
          //     selected: false,
          //     purchaseDate:
          //       el.purchaseDate != null
          //         ? moment(el.purchaseDate).format("DD-MM-YYYY hh:mm:ss")
          //         : "",
          //   };
          // });

          this.tableProperties.totalRows = resp.totalElements;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getPurchaseDateZoneWise(dateAndTime){
      let time = moment.utc(dateAndTime,'YYYY-MM-DD hh:mm:ss').local().format('DD/MM/YYYY hh:mm A');
      return time != 'Invalid date'?time:'-';
    },

    // for download returns orders
    downloadOrderReturns() {
      this.loadingWidget = true;
      downloadReturns(
        this.orderId,
        this.selectedStatusList,
        this.selectedChannelList,
        this.selectedShipmentList,
        this.selectedPaymentList,
        this.startDate,
        this.endDate,
      )
        .then((res) => {
          this.loadingWidget = false;
        })
        .catch((err) => {
          var self = this;
          // for read the blob response type
          var myReader = new FileReader();
          myReader.addEventListener("loadend", function(e) {
            self.alertMsg = JSON.parse(e.srcElement.result).message;
          });
          //start the reading process.
          myReader.readAsText(err);
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // get order id
    getOrderId(event) {
      this.orderId = event;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getReturnOrdersList();
    },

    // get select status type
    getSelectedStatus(event) {
      this.selectedStatusList = event;
      this.getReturnOrdersList();
    },

    // select channel
    getSelectedChannel(event) {
      this.selectedChannelList = event;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getReturnOrdersList();
    },

    // select shipment type
    getSelectedShipmentTypes(event) {
      this.selectedShipmentList = event;
      this.getReturnOrdersList();
    },

    // select payment type
    getSelectedPaymentTypes(event) {
      this.selectedPaymentList = event;
      this.getReturnOrdersList();
    },

    // select purchased date
    getPurchaseDate() {
      if (this.selectedPurchaseDate.length > 1) {
        this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
        this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
        this.getReturnOrdersList();
      }
    },

    // clear purchased date
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getReturnOrdersList();
    },

    // pagination
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getReturnOrdersList();
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.tableProperties.allSelected = false;
      this.getReturnOrdersList();
    },

    // for order detail page
    goToOrderDetailsPage(orderDetails) {
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },

    goBackToOrder() {
      this.isOrderDetailsTab = false;
    },

    // for close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // open a desktop filer
    openDesktop_filter(){
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    }
  },
};
</script>

<style scoped>
.show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
::v-deep .form-control {
  height: 37px !important;
}
.return-table ::v-deep table thead tr th:first-child {
  z-index: 1;
}
.return-table ::v-deep table thead tr th {
  text-align: center !important;
}
.return-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.return-table ::v-deep table thead tr th:nth-child(2) {
  text-align: left !important;
}
.return-table ::v-deep table tbody tr td:nth-child(2) {
  text-align: left !important;
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 2;
}
::v-deep .pagination-section .mobile-download-ship-icons{
  text-align: right;
}
.order-detail-page ::v-deep .whithout-tab-content{
    margin: 0;
  }
.return-table ::v-deep .table-pagination{
  align-items: center !important;
}

/* .mobile-filter {
  display: none;
} */
/* .open-mobile-filter {
  display: none;
} */

/* for responsive layout */
@media only screen and (max-width: 768px) {
  .mobile-top-space{
    margin-top: 15px !important;
  }
  .desktop-return-filter {
    /* display: none; */
    z-index: 2;
    margin-top: 2rem;
  }
  .card-padding{
    margin-top: 10px;
  }
  /* ::v-deep .table-pagination{
    justify-content: end !important;
  } */
  /* .mobile-filter {
    display: block;
  } */
  /* .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    z-index: 222222;
  } */
}
@media only screen and (max-width: 340px){
  .return-table ::v-deep .table-pagination .page-link{
    min-width: 10px !important;
  }
  .return-table ::v-deep .table-pagination .custom-select{
    width: 65px !important;
  }
}

@media only screen and (max-width: 380px){
  .summary_refresh_text {
    font-size: 12px;
    margin-right: 0px;
  }
}

/* @media only screen and (min-width: 1470px){
  .return-table ::v-deep .desktop-download-ship-icons{
      position: relative;
      left: 90px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px){
  .return-table ::v-deep .desktop-download-ship-icons{
      position: relative;
      left: 20px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1470px){
  .return-table ::v-deep .desktop-download-ship-icons{
      position: relative;
      left: 60px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1200px){
  .return-table ::v-deep .desktop-download-ship-icons{
      position: relative;
      left: -30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1099.99px){
  .return-table ::v-deep .desktop-download-ship-icons{
      position: relative;
      left: -60px;
  }
} */
</style>
